import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// @mui

import {
  Link,
  Stack,
  Select,
  FormHelperText,
  MenuItem,
  Grid,
  CircularProgress,
  InputLabel,
  IconButton,
  InputAdornment,
  TextField,
  Input,
  Checkbox,
  Box,
  Card,
  Typography,
  Modal,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// utils
import { LoadingButton } from '@mui/lab';
import { API_URL, IMAGE_URL } from '../../../utils/constant';

import { uploadImage } from '../../../api/upload';
import { getCategoryList } from '../../../api/category-msi';

// components

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  maxHeight: '100%',
};

// ----------------------------------------------------------------------

// ShopProductCard.propTypes = {
//     product: PropTypes.object,
// };

export default function AddProduct({ open, onPopUpClose, loading, onSubmit, initialValues }) {
  const [formData, setFormData] = useState({ ...initialValues });
  const [selectedFile, setSelectedFile] = useState(null);

  const [selectedFileSrc, setSelectedFileSrc] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(0);
  const [subCategoryDisabled, setSubCategoryDisabled] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [formError, setFormError] = useState(false);
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

  };

  // const handleClick = async (event) => {
  //   event.preventDefault();
  //   setFormError(false);

  //   if (formData.name === '' || formData.description === '' || formData.numberField === '') {
  //     setFormError(true);
  //     return false;
  //   }

  //   if (selectedFile) {
  //     const url = await uploadImage('product-msi', selectedFile);
  //     formData.fileUrl = `${IMAGE_URL}/${url.data}`;
  //   }

  //   console.log('Product Form Data:', formData);

  //   setSelectedFileSrc(null);
  //   onSubmit(formData);
  //   console.log('Product Submitted');

  //   // Reset the form data after submission
  //   setFormData({ ...initialValues });

  //   return true;
  // };


  const handleClick = async (event) => {
    event.preventDefault();
    setFormError(false);

    if (formData.name === '' || formData.description === '') {
      setFormError(true);
      return false;
    }

    if (selectedFile) {
      const url = await uploadImage('product-msi', selectedFile);
      formData.fileUrl = `${IMAGE_URL}/${url.data}`;
    }

    console.log('Product Form Data:', formData);

    setSelectedFileSrc(null);
    onSubmit(formData);
    console.log('Product Submitted');

    // Reset the form data after submission
    setFormData({ ...initialValues, numberField: '' });

    return true;
  };

  // const handleClick = async (event) => {
  //   event.preventDefault();
  //   setFormError(false);

  //   if (formData.name === '' || formData.description === '' || formData.numberField === '') {
  //     setFormError(true);
  //     return false;
  //   }

  //   if (selectedFile) {
  //     const url = await uploadImage('product-msi', selectedFile);
  //     formData.fileUrl = `${IMAGE_URL}/${url.data}`;
  //   }

  //   console.log('Product Form Data:', formData);

  //   setSelectedFileSrc(null);
  //   onSubmit(formData);
  //   console.log('Product Submitted');

  //   // Reset the form data after submission
  //   setFormData(initialValues);

  //   return true;
  // };


  const handleImageUpload = (event) => {
    event.preventDefault();
    setFormError(false);
    const file = event.target.files[0];
    setSelectedFile(file);
    const reader = new FileReader();

    reader.onload = () => {
      const base64Image = reader.result;
      // const name = 'imageUrl'
      // Use the base64Image string as needed
      setSelectedFileSrc(base64Image);
      // setFormData((prevFormData) => ({
      //     ...prevFormData,
      //     [name]: base64Image,
      // }));
    };
    reader.readAsDataURL(file);
  };

  // for pdf only
  // const handleImageUpload = (event) => {
  //   event.preventDefault();
  //   setFormError(false);
  //   setErrorMessage('');

  //   const file = event.target.files[0];

  //   // Check if the file is a PDF
  //   if (file && file.type === 'application/pdf') {
  //     setSelectedFile(file);

  //     // Optional: Read the file as a data URL if you need to display it or process it further
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       // Handle the file data if needed
  //       const base64File = reader.result;
  //       // For PDF files, you might handle the data differently
  //       console.log(base64File); // Just to see the base64 data
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     // Provide feedback if the file is not a PDF
  //     setFormError(true);
  //     setErrorMessage('Only PDF files are allowed.');
  //   }
  // };
  // const { open } = props;
  
  const handleClose = () => {
    onPopUpClose();
  };

  const getCategoryListData = async () => {
    try {
      const data = {
        page: 1,
        limit: 100,
        keyword: '',
      };
      const categoryRes = await getCategoryList(data);
      const { categoryList, count } = categoryRes.data;

      setCategoryList(categoryList);
    } catch (error) {
      if (error?.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      alert(error?.response?.data?.error || 'Something went wrong');
    }
  };

  useEffect(() => {
    getCategoryListData();

    setFormData(initialValues);
  }, [initialValues]);

  return (
    <Grid container>
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <h2 style={{ marginLeft: '10px' }}>Add Product</h2>
              <IconButton onClick={handleClose}>X</IconButton>
            </Stack>
            <form id="loginForm" onSubmit={handleClick}>
              <Stack spacing={3}>
                <TextField
                  name="name"
                  label="Product Name"
                  value={formData.name}
                  onChange={handleFormChange}
                  required
                />
                <TextField
                  // type="number"
                  name="numberField"
                  label="Number"
                  value={formData.numberField}
                  onChange={handleFormChange}
                />
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="productType"
                  value={formData.productType}
                  label="Age"
                  onChange={(event) => {
                    handleFormChange(event);
                  }}
                >
                  <MenuItem value={'default'}>--- Select Product Type ---</MenuItem>
                  <MenuItem value={'free'}>Free</MenuItem>
                  <MenuItem value={'paid'}>Paid</MenuItem>
                </Select>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="categoryId"
                  value={formData.categoryId}
                  label=""
                  onChange={(event) => {
                    handleFormChange(event);
                  }}
                >
                  {' '}
                  <MenuItem value={0}>--- Select Category ---</MenuItem>
                  {categoryList
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>

                <TextField type="file" name="fileUrl" onChange={handleImageUpload} />
       {/* {formError && <p style={{ color: 'red' }}>{errorMessage}</p>} */}

                <img height={100} width={100} src={selectedFileSrc || formData.fileUrl} alt="" />

                {formError && <FormHelperText error>This field is required.</FormHelperText>}

                <Stack alignItems="center" spacing={2}>
                  {loading ? (
                    <Stack alignItems="center" justifyContent="center">
                      <CircularProgress />
                    </Stack>
                  ) : (
                    <LoadingButton fullWidth size="large" type="submit" variant="contained">
                      {formData.id !== '' ? 'Update Product' : 'Add Product'}
                    </LoadingButton>
                  )}
                </Stack>
              </Stack>
            </form>
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
}
